import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { Fragment, useRef } from 'react';
import { BsArrowLeftShort } from 'react-icons/bs';

import Video from '../marketing/Video';

export interface Props {
  show: boolean;
  onClose: () => void;
  link?: string;
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

export default function Presentation(props: Props) {
  const closeButtonRef = useRef(null);

  return (
    <div
      className={clsx('absolute block', {
        hidden: !props.show,
      })}
    >
      <Transition show={props.show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={closeButtonRef}
          onClose={props.onClose}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white md:bg-black md:bg-opacity-50 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-scroll">
            <div className="flex min-h-full justify-center sm:items-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="flex min-h-dvh max-w-full transform justify-center bg-white p-8 shadow-xl transition-all sm:space-y-4 md:min-h-0 md:max-w-2xl md:rounded-3xl md:px-16 md:pt-2 lg:max-w-4xl lg:px-24 lg:pb-12 xl:max-w-5xl">
                  <XMarkIcon
                    className="absolute right-8 top-10 hidden h-7 cursor-pointer text-primary-600 md:block"
                    onClick={props.onClose}
                    ref={closeButtonRef}
                  />
                  <div className="w-screen md:max-w-xl xl:max-w-2xl">
                    <div>
                      {props.link ? (
                        <Video link={props.link} title={props.title} />
                      ) : null}
                      <div className="mt-6 space-y-8 px-3 text-neutral-500">
                        <p className="text-justify text-sm sm:text-base">
                          {props.description || props.children}
                        </p>
                        <button
                          type="button"
                          className="mt-3 flex items-center gap-x-2 rounded-xl border border-primary-600 py-2 pl-2 pr-4 text-base font-medium text-primary-600 shadow-xl transition duration-200 ease-in-out hover:scale-110 md:hidden"
                          onClick={props.onClose}
                          ref={closeButtonRef}
                        >
                          <BsArrowLeftShort size={25} />
                          <p>Go back</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
