/**
 * @see https://umami.is/docs/tracker-functions
 * @param event
 * @param meta
 */
export function umamiTrack(
  event: string,
  meta: Record<string, string | number> = {},
) {
  if (globalThis.umami) {
    void globalThis.umami.track(event, meta);
  }
}
