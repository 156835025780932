import { type ISourceOptions } from '@tsparticles/engine';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import React, { useEffect, useMemo, useState } from 'react';
import { loadFull } from 'tsparticles';

export interface ParticelsTsProps {
  isSmall?: boolean;
  isStatic?: boolean;
  className?: string;
}

export default function ParticlesTs(props: ParticelsTsProps) {
  const [init, setInit] = useState(false);
  useEffect(() => {
    void initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = useMemo(
    () =>
      ({
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'canvas',
          events: {
            onHover: { enable: !props.isStatic, mode: 'repulse' },
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            repulse: { distance: 80, duration: 0.7 },
          },
        },
        particles: {
          color: { value: '#ffffff' },
          links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          move: {
            direction: 'none',
            enable: true,
            outModes: 'bounce',
            random: false,
            speed: 1.2,
            straight: false,
          },
          number: {
            value: props.isSmall ? 80 : 40,
          },
          opacity: { value: 0.5 },
          shape: { type: 'circle' },
          size: { value: { min: 1, max: 5 } },
        },
        detectRetina: true,
        fullScreen: { enable: !props.isSmall, zIndex: 0 },
      }) satisfies ISourceOptions,
    [props.isSmall, props.isStatic],
  );

  if (!init) {
    return null;
  }

  return <Particles className={props.className} options={options} />;
}
