import Link from 'next/link';
import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';

import { umamiTrack } from '../../utils/umami';
import Presentation from '../videos/Presentation';

export function LinksChildren() {
  const [showPresentation, setShowPresentation] = useState(false);
  return (
    <div className="mt-5 flex flex-col items-center gap-5">
      <div className="flex flex-row lg:space-x-12">
        <Link
          href="/nmrium"
          className="hidden rounded-2xl border border-transparent bg-white px-7 py-3 text-lg font-medium text-primary-600 transition duration-200 ease-in hover:scale-110 lg:inline-flex"
        >
          Start now
        </Link>
        <Link
          href="/nmrium/demo"
          className="hidden rounded-2xl border border-transparent bg-white px-7 py-3 text-lg font-medium text-primary-600 transition duration-200 ease-in hover:scale-110 lg:inline-flex"
        >
          Demo
        </Link>
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            setShowPresentation(true);
            umamiTrack('quickTour');
          }}
          className="inline-flex cursor-pointer items-center justify-center gap-x-2 rounded-2xl border border-white px-7 py-3 text-lg font-medium text-white transition duration-200 ease-in-out hover:scale-110 hover:bg-white hover:text-primary-600"
        >
          <FaPlay size="15px" />
          <div>Quick Tour</div>
        </button>
      </div>
      <Presentation
        show={showPresentation}
        onClose={() => setShowPresentation(false)}
        link="https://player.vod2.infomaniak.com/embed/1jhvl2uqlbrgs"
        title="Introduction to NMRium"
        description="With NMRium, you can visualize and process NMR
        spectra. In this introduction video, we present the
        processing of a 13C NMR spectrum of cytisine."
      />
    </div>
  );
}
